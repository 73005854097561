import { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { useConfig } from '~components/hooks';
import { useTranslation } from 'react-i18next';

const TopMenu: FunctionComponent = () => {
  const { t } = useTranslation();
  const getConfig = useConfig();

  return (
    <nav className="menu-landing">
      <ul>
        <li>
          <a href={`https://${getConfig.seo.domain}`}>
            <span className="icon material-icons" data-icon="&#xe88a;"></span>
            <span>{t('Home')}</span>
          </a>
        </li>
        <li className="active">
          <NavLink to="/">
            <span className="icon material-icons">face</span>
            <span>{t('Cabinet')}</span>
          </NavLink>
        </li>
        <li className="animate">
          <a href={getConfig?.seo?.adUrl} target="_blank" rel="noreferrer">
            <span className="icon material-icons" data-icon="&#xf001;"></span>
            <span>{t('For advertising')}</span>
          </a>
        </li>
        <li>
          <NavLink to="/faq">
            <span className="icon material-icons" data-icon="help"></span>
            <span>FAQ</span>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default TopMenu;
