import { createReducerContext } from 'react-use';
import update from 'immutability-helper';

import _ from 'lodash';

export { Withdraw } from './Withdraw';

const initialState = {
  Withdraw: { isOpen: false },
};

type Action = {
  type: string;
  modalName?: string;
  props?: any;
};

const reducer = (state: any, action: Action) => {
  const { type, props, modalName } = action;

  const modal = state[modalName];

  switch (type) {
    case 'updateProps': {
      return update(state, {
        [modalName]: {
          $merge: props,
        },
      });
    }
    case 'onClose': {
      if (_.isFunction(modal?.onClose)) {
        modal.onClose();
      }

      return update(state, {
        [modalName]: {
          $set: {
            isOpen: false,
          },
        },
      });
    }
    case 'onShow': {
      return update(state, {
        [modalName]: {
          $set: {
            isOpen: true,
            ...props,
          },
        },
      });
    }
    case 'onCloseAll': {
      return update(state, {
        $set: {
          isOpen: false,
        },
      });
    }
    default:
      return state;
  }
};

export const [useSharedState, ModalsProvider] = createReducerContext(
  reducer,
  initialState,
);
