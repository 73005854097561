const merge = (
  existing: any[],
  incoming: any[],
  { readField, mergeObjects },
) => {
  const merged: any[] = existing ? existing.slice(0) : [];
  const idToIndex: Record<string, number> = Object.create(null);

  if (existing) {
    existing.forEach((item, index) => {
      idToIndex[readField('id', item)] = index;
    });
  }
  incoming.forEach(item => {
    const id = readField('id', item);
    const index = idToIndex[id];
    if (typeof index === 'number') {
      // Merge the new author data with the existing author data.
      merged[index] = mergeObjects(merged[index], item);
    } else {
      // First time we've seen this author in this array.
      idToIndex[id] = merged.length;
      merged.push(item);
    }
  });
  return merged;
};

export const cacheConfig = {
  typePolicies: {
    Query: {
      fields: {
        getConfig: {
          merge(existing: any, incoming: any, { mergeObjects }) {
            return mergeObjects(existing, incoming);
          },
        },
      },
    },
  },
};
