import { useQuery, gql } from '@apollo/client';
import { Profile as ProfileType } from '~types';
import _ from 'lodash';

export const GET_PROFILE = gql`
  query getProfile {
    getProfile {
      id
      userName
      userId
      avatar
      social
      referralMoney
      promoCodePercent
      promoCodeIncomePercent
      getMaxPromoCodeCount
    }
  }
`;

type Profile = ProfileType & { createdFormat?: string };

interface Data {
  getProfile: Profile;
}

export const useProfile = (): Profile => {
  const { data } = useQuery<Data>(GET_PROFILE);

  return data?.getProfile;
};
