import { useQuery, gql } from '@apollo/client';
import { Config as ConfigType } from '~types';
import _ from 'lodash';

export const GET_CONFIG = gql`
  query getConfig {
    getConfig {
      hostname
      isEU
      getCountries {
        id
        key
        name
        image
        amountExclude
      }
      minSumWithdraw
      getCurrency {
        id
        char
        symbol
      }
      getWithdrawalSystems {
        id
        name
        key
        image
        placeholder
      }
      seo
      userAgent
    }
  }
`;

interface Data {
  getConfig: ConfigType;
}

export const useConfig = (): ConfigType => {
  const { data } = useQuery<Data>(GET_CONFIG);

  return data?.getConfig;
};
