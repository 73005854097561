import {
  createHttpLink,
  split,
  ApolloLink,
  ApolloClient,
  InMemoryCache,
} from '@apollo/client';
import { createClient } from 'graphql-ws';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';

import { cacheConfig } from './index';

const initialState = window.__INITIAL_STATE__ || {};

const cache = new InMemoryCache(cacheConfig).restore(initialState);

const wsLink = new GraphQLWsLink(
  createClient({
    url: `ws${window.location.protocol.includes('https') ? 's' : ''}://${
      window.location.host
    }/graphql`,
  }),
);

const httpLink = ApolloLink.from([
  createHttpLink({
    uri: `/graphql`,
  }),
]);

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const client = new ApolloClient({
  cache,
  link,
});

export default client;
