import 'dayjs/locale/ru';

import { hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { YaMetricaProvider } from './provider/yametrica';
import { i18Next } from '~public/frontend/i18n';
import { I18nextProvider } from 'react-i18next';
import { ApolloProvider } from '@apollo/client';
import { loadableReady } from '@loadable/component';

import RenderRoutes from './router';
import { ModalsProvider } from '~components/modals';
import client from './apollo/config';

import '~public/frontend/scss/main.scss';

const container = document.getElementById('root');

loadableReady(() => {
  hydrateRoot(
    container,
    <ApolloProvider client={client}>
      <CookiesProvider>
        <I18nextProvider i18n={i18Next}>
          <YaMetricaProvider>
            <ModalsProvider>
              <BrowserRouter
                basename={
                  window.defaultLanguage === 'ru' ? undefined : i18Next.language
                }
              >
                <RenderRoutes />
              </BrowserRouter>
            </ModalsProvider>
          </YaMetricaProvider>
        </I18nextProvider>
      </CookiesProvider>
    </ApolloProvider>,
  );
});
